import { BffApiCreateItemError, UpdateItemGqlError } from '../../../../types';

import {
  CreateItemTypeCacheKeys,
  ItemTypeID,
  ItemTypeName,
  ItemTypePrice,
  ItemTypeViewPrice,
  ItemTypeViewPriceCurrency
} from '../../itemTypesTypes';

import { CompanyID, CompanyNanoID } from '../../../companies/companiesTypes';

import { useFinCreateQuery } from '../../../common/hooks/base/reactQuery/useFinCreateQuery';
import { CREATE_ITEM_TYPE_QUERY } from '../../queries/createItemType.query';
import { UpdateBillGqlStatus } from '../../../bills/billsTypes';
import { ItemCategoryID } from '../../../itemCategories/itemCategoriesTypes';
import { ItemTagID, ItemTagPosition } from '../../../itemTags/itemTagsTypes';

interface CreateItemTypeOptions {
  cacheKeys?: CreateItemTypeCacheKeys;
}

export interface CreateItemTypeInput {
  companyId?: CompanyID;
  companyNanoId?: CompanyNanoID;
  itemCategoryId: ItemCategoryID;
  itemTypeItemTagsAttributes?: {
    position: ItemTagPosition;
    itemTagId: ItemTagID;
  }[];
  name?: ItemTypeName;
  price: ItemTypePrice;
  viewPrice?: ItemTypeViewPrice;
  viewPriceCurrency?: ItemTypeViewPriceCurrency;
}

export type CreateItemTypeError = BffApiCreateItemError;

interface CreateItemTypeItemType {
  id: ItemTypeID;
  name: ItemTypeName;
  price: ItemTypePrice;
  viewPrice: ItemTypeViewPrice;
  viewPriceCurrency: ItemTypeViewPriceCurrency;
}

export interface CancelBillError {
  fullMessages: UpdateItemGqlError;
}

interface CreateItemTypeResponseType<CreateItemTypeItemType> {
  createItemType: {
    status: UpdateBillGqlStatus;
    record: CreateItemTypeItemType;
    errors: UpdateItemGqlError;
    recordId: ItemTypeID;
  };
}

const action = 'createItemType';

function useCreateItemType({ cacheKeys }: CreateItemTypeOptions = {}) {
  const {
    createQuery,
    createQueryErrorMessage,
    createQueryLoading,
    createQueryReset
  } = useFinCreateQuery<
    CreateItemTypeInput,
    CreateItemTypeResponseType<CreateItemTypeItemType>,
    CreateItemTypeError,
    CreateItemTypeItemType
  >({ action, query: CREATE_ITEM_TYPE_QUERY, cacheKeys });

  return {
    createItemTypeLoading: createQueryLoading,
    createItemTypeErrorMessage: createQueryErrorMessage,
    createItemType: createQuery,
    createItemTypeReset: createQueryReset
  };
}

export default useCreateItemType;
