import { gql } from 'graphql-request';

export const CREATE_ITEM_TYPE_QUERY = gql`
  mutation CreateItemType(
    $companyId: ID
    $companyNanoId: ID
    $itemCategoryId: ID!
    $itemTypeItemTagsAttributes: [ItemTagAttributes!]
    $name: String
    $price: Float
    $viewPrice: Float
    $viewPriceCurrency: String
    $description: String
    $imageUrl: String
    $newClientDefaultAt: Date
  ) {
    createItemType(
      input: {
        companyId: $companyId
        companyNanoId: $companyNanoId
        itemCategoryId: $itemCategoryId
        itemTypeItemTagsAttributes: $itemTypeItemTagsAttributes
        name: $name
        price: $price
        viewPrice: $viewPrice
        viewPriceCurrency: $viewPriceCurrency
        description: $description
        imageUrl: $imageUrl
        newClientDefaultAt: $newClientDefaultAt
      }
    ) {
      errors {
        fullMessages
      }
      record {
        id
        name
        price
        viewPrice
        viewPriceCurrency
      }
      recordId
      recordNanoId
      recordUuid
      status
    }
  }
`;
